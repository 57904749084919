<template>
    <div v-if="shown" class="alert alert-secondary fixed-bottom text-center mb-0">
        <button aria-label="Close" class="close" data-dismiss="alert" type="button" @click="close" >
            <span aria-hidden="true">&times;</span>
        </button>
        <small v-html="translations.alert_text" />
        <button class="btn btn-primary mx-2" type="button" @click="accept">
            {{ translations.i_accept }}
        </button>
    </div>
</template>

<script>
    // import Translations from "./insert-me-to-translate.vue";

    import { 
        privacyCookieName, 
        addUserInteractionEvents,
    } from '../utils';

    export default {
        props: ['cookieUrl'],
        data() {
            let alert_text_translation = gettext('This website allows the use of first-party and third-party cookies to provide you with a better experience. By clicking on "I accept" button you consent to the use of cookies. To learn more or to manage your cookie preferences, please see our <a data-href="%(cookie_url)s">Cookie Policy</a>')
        
            return {
                shown: false,
                hasPrivacyCookie: !!this.$cookies.get(privacyCookieName) || false,
                translations: {
                    alert_text: alert_text_translation.replace("%(cookie_url)s", this.cookieUrl),
                    i_accept: gettext("I accept")
                }
            };
        },
        mounted() {
            addUserInteractionEvents(this.show)
        },
        methods: {
            close() {
                this.shown = false
            },
            accept() {
                this.$cookies.set(privacyCookieName, '1', '1d', '/', locations.cookie_domain);
                this.$root.$emit('setPrivacyCookie', true);
                this.shown = false
            },
            show() {
                if (!this.hasPrivacyCookie) {
                    this.shown = true;
                }
            },
        }
    }
</script>
