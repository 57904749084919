<template>
  <div 
    class="modal fade modal__loader"
    :class="{ 'show': shown }" 
    id="modal-loader" 
    tabindex="-1" 
    role="dialog"
    aria-labelledby="modalLoader" style="display: block; padding-right: 15px;"
    :style="shown ? 'display: block; padding-right: 15px' : 'display: none'"
    data-keyboard="false"  
  >
      <div class="loader"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    'loaded': {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      shown: 'ui/loader/shown',
    }),
  },
  mounted() {
    if (this.loaded) {
      this.$store.dispatch("ui/loader/show")
    }
    
    // prevent ESC key from closing the modal
    document.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    handleKeyDown(event) {
      // If ESC key (keyCode 27) is pressed and modal is shown, prevent default action
      if (event.keyCode === 27 && this.shown) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }
}
</script>


<style scoped>
.loader {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}
/* Prevent overlap with other BS modals */
.modal__loader {
  z-index: 2000;
}
</style>