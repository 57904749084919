var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.classes,
      attrs: { href: _vm.href, id: "phone-button" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [
      _vm.hideText
        ? _c("i", { staticClass: "icon-phone align-middle" })
        : _c(
            "svg",
            {
              staticClass: "mr-1 mr-sm-2",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 24 24",
                fill: "currentColor",
                width: "18",
                height: "18",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  d: "M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z",
                  "clip-rule": "evenodd",
                },
              }),
            ]
          ),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.buttonText) } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }