var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.componentType,
    {
      tag: "component",
      class: [
        "ui-button",
        _vm.buttonType,
        _vm.appearance,
        _vm.size,
        {
          loading: _vm.loading,
          disabled: _vm.isDisabled || _vm.currentLoading,
          block: _vm.block,
          inline: _vm.inline,
        },
        { "horizontal-shake": _vm.animate === "horizontal-shake" },
      ],
      attrs: {
        href: _vm.href ? _vm.href : null,
        disabled: _vm.isDisabled,
        "aria-label": _vm.ariaLabel || _vm.label,
        "aria-disabled": _vm.isDisabled,
        type: _vm.type,
      },
      on: {
        click: _vm.handleClick,
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.handleClick.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.handleClick.apply(null, arguments)
          },
        ],
        animationend: _vm.handleAnimationEnd,
      },
    },
    [
      _vm._t("icon-left", function () {
        return [
          _vm.icon && _vm.iconPosition === "left"
            ? _c("span", { class: ["icon", _vm.icon] })
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _vm.loading
        ? _c("span", { staticClass: "loader", class: _vm.loadingClass })
        : _c(
            "span",
            { staticClass: "ui-button__label", class: _vm.labelClass },
            [
              _vm._t("default", function () {
                return [_vm._v(_vm._s(_vm.label))]
              }),
            ],
            2
          ),
      _vm._v(" "),
      _vm._t("icon-right", function () {
        return [
          _vm.icon && _vm.iconPosition === "right"
            ? _c("span", { class: ["icon", _vm.icon] })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }