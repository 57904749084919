var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showSearchModal
    ? _c("div", { staticClass: "search-dialog" }, [
        _c("div", { attrs: { id: "search-modal-content" } }, [
          _c("div", { staticClass: "modal-body filters" }, [
            _c(
              "div",
              { attrs: { id: "search-app" } },
              [
                _c("search-form", {
                  ref: "searchForm",
                  attrs: {
                    "init-search-key": _vm.initSearchKey,
                    "init-category": _vm.initCategory,
                    "init-city": _vm.initCity,
                    "init-region": _vm.initRegion,
                    "init-district": _vm.initDistrict,
                    "init-language-code": _vm.initLanguageCode,
                    "all-the-regions-translation": _vm.allTheRegionsTranslation,
                    "is-skokka": _vm.isSkokka,
                    "cities-in-the-region-translation":
                      _vm.citiesInTheRegionTranslation,
                    "all-the-cities-in-the-region-translation":
                      _vm.allTheCitiesInTheRegionTranslation,
                    "cities-in-the-country-translation":
                      _vm.citiesInTheCountryTranslation,
                    "all-the-cities-translation": _vm.allTheCitiesTranslation,
                    "all-the-districts-translation":
                      _vm.allTheDistrictsTranslation,
                    "is-advanced-search-active": _vm.isAdvancedSearchActive,
                    "is-new-region-listing-active":
                      _vm.isNewRegionListingActive,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "modal-footer filters position-sticky fixed-bottom bg-white",
            },
            [
              _vm.isAdvancedSearchActive == "True"
                ? [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link skokka-text col",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteAllSearchFilters()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.i18nStrings.deleteAll) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                : [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link skokka-text col",
                        attrs: { type: "button", "data-dismiss": "modal" },
                      },
                      [_vm._v(_vm._s(_vm.i18nStrings.close))]
                    ),
                  ],
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary col",
                  attrs: {
                    id: "vue-search-form-submit-btn",
                    form: "vue-search",
                    type: "submit",
                  },
                },
                [
                  _c("i", [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          "stroke-width": "1.5",
                          stroke: "currentColor",
                          width: "24",
                          height: "24",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            d: "M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.i18nStrings.search) +
                      "\n        "
                  ),
                ]
              ),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }