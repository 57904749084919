<template>
  <div
    class="mb-3"
  >
    <div
      class="d-inline"
      @click="userAction"
    >
      <a
        v-if="text7"
        data-toggle="collapse"
        href="#collapseHeaderText"
        role="button"
        aria-expanded="true"
        aria-controls="collapseHeaderText"
      >
        <h1
          class="item-title d-inline text-dark"
          v-html="text1"
        >
        </h1>
        <div
          class="d-inline"
        >
          <i class="small fa fa-chevron-right text-dark"></i>
        </div>
      </a>

      <h1
        v-else
        class="item-title d-inline text-dark"
        v-html="text1"
      >
      </h1>
    </div>

    <div
      v-if="text7"
      class="list-info mt-3 mb-3 card shadow border-0 collapse show txt_seo_2"
      id="collapseHeaderText"
    >
      <p
        class="mb-0 p-4"
        v-html="text7"
      >
      </p>
    </div>
  </div>
</template>
<script>
import debounce from 'lodash/debounce';

export default {
  name: 'SeoHeader',
  props: {
    text7: {
      type: String,
      required: false,
    },
    text1: {
      type: String,
      required: true,
    },

  },
  data() {
    return {
      user_action: false
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    userAction() {
      this.user_action = true;
    },
   handleScroll: debounce(function() {
    if (!this.user_action) {
      const collapseHeaderTextElement = document.getElementById('collapseHeaderText');
      const collapseTriggerElement = document.querySelector('[data-toggle="collapse"][href="#collapseHeaderText"]');

      if (collapseHeaderTextElement && collapseTriggerElement) {
        collapseHeaderTextElement.classList.remove('show');
        collapseTriggerElement.setAttribute('aria-expanded', 'false');
      }
    }}, 300), // Debounce to limit the rate of function execution
  }
};
</script>
