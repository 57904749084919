const modalOpenClassName = 'modal-open';
const events = new Set(["mousemove", "touchmove", "mousewheel", "keydown", "scroll"]);

export const getLightboxName = ({ el }) => el && typeof el.closest == 'function' ? el.closest('.lightboxManager > div[name]')?.getAttribute('name') : null;

export const releaseScroll = () => document?.body && document.body.classList.contains(modalOpenClassName) && document.body.classList.remove(modalOpenClassName);

export const blockScroll = () => {
    document?.body && !document.body.classList.contains(modalOpenClassName) && document.body.classList.add(modalOpenClassName)
}

export const filterWorkNames = ({ names, state }) => {
    // Ensure workNames is an array
    let workNames = !Array.isArray(names) ? [names] : names;
    
    // Filter workNames based on the conditions
    workNames = workNames.filter(name => 
      state.registeredModels.indexOf(name) !== -1 && 
      state.queue.indexOf(name) === -1
    );
  
    return workNames;
  }

export const addUserInteractionEvents = (callback) => {
    if(!callback) {
        console.warn('No callback provided to addUserInteractionEvents')
    } else {
    
        // Wrapper function to remove event listeners after callback execution
        const wrappedCallback = (event) => {
            callback(event);
            removeUserInteractionEvents(wrappedCallback);
        };

        events.forEach((event) => {
            document.addEventListener(event, wrappedCallback, false);
        });

        return wrappedCallback;
    }
}

export const removeUserInteractionEvents = (callback) => {
    if(!callback) {
        console.warn('No callback provided to removeUserInteractionEvents')
    } else {
        events.forEach((event) => {
            document.removeEventListener(event, callback, false);
        });
    }
};