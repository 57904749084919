<template>
  <div class="search-dialog"  v-if="showSearchModal">
    <div id="search-modal-content">

      <!-- Modal Header -->
<!--      <div class="modal-header">-->
<!--        <h5 class="modal-title" id="statcicBackdropLabel">{{ i18nStrings.search }}-->
<!--        </h5>-->
<!--        <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
<!--          <span aria-hidden="true">&times;</span>-->
<!--        </button>-->
<!--      </div>-->
      <!-- End Modal Header -->


      <!-- Modal body -->
      <div class="modal-body filters">
        <div id="search-app">


          <search-form
              ref="searchForm"
              :init-search-key="initSearchKey"
              :init-category="initCategory"
              :init-city="initCity"
              :init-region="initRegion"
              :init-district="initDistrict"
              :init-language-code="initLanguageCode"

              :all-the-regions-translation="allTheRegionsTranslation"
              :is-skokka="isSkokka"
              :cities-in-the-region-translation="citiesInTheRegionTranslation"
              :all-the-cities-in-the-region-translation="allTheCitiesInTheRegionTranslation"
              :cities-in-the-country-translation="citiesInTheCountryTranslation"
              :all-the-cities-translation="allTheCitiesTranslation"
              :all-the-districts-translation="allTheDistrictsTranslation"

              :is-advanced-search-active="isAdvancedSearchActive"
              :is-new-region-listing-active="isNewRegionListingActive"
          ></search-form>

        </div> <!-- end of #search-app -->
      </div> <!-- end of .modal-body -->


      <!-- Modal footer -->
      <div class="modal-footer filters position-sticky fixed-bottom bg-white">
        <template v-if="isAdvancedSearchActive == 'True'">
          <button type="button" class="btn btn-link skokka-text col" @click="deleteAllSearchFilters()">
            {{ i18nStrings.deleteAll }}
          </button>
        </template>
        <template v-else>
          <button type="button" class="btn btn-link skokka-text col" data-dismiss="modal">{{ i18nStrings.close }}</button>
        </template>
        <button id="vue-search-form-submit-btn" form="vue-search" type="submit" class="btn btn-primary col">
          <i>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="24" height="24">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
            </svg>
          </i>
          {{ i18nStrings.search }}
        </button>
      </div> <!-- end of .modal-footer -->


    </div> <!-- end of #dialog-content -->
  </div> <!-- end of .modal-dialog .modal-lg .modal-dialog-centered -->
</template>

<script>
// import Translations from "./insert-me-to-translate.vue";

export default {
  name: "SearchModal",
  components: {
    SearchForm: () => import(/* webpackChunkName: "chunck-search-form" */ './search-form.vue'),
  },
  props: {
    isNewRegionListingActive:{type:Boolean,default:false},
    // block of props used for search-form
    initSearchKey: String,
    initCategory: String,
    initCity: String,
    initRegion: String,
    initDistrict: String,
    initLanguageCode: String,
    // end of block of props used for search-form

    // context stuff
    regions: String,
    allTheRegionsTranslation: String,
    isSkokka: String,
    citiesInTheRegionTranslation: String,
    allTheCitiesInTheRegionTranslation: String,
    citiesInTheCountryTranslation: String,
    allTheCitiesTranslation: String,
    allTheDistrictsTranslation: String,
    // end of context stuff

    // tagging system
    isAdvancedSearchActive: String,
    hierarchy: String,
    // end of tagging system
  },
  computed: {
    showSearchModal: function () {
      if (window.hj){
        window.hj('event', 'show_search_modal')
      }
      return this.$store.state.ui.searchModal.showSearchModal;
    },
    hierarchyObj: function () {
      let temp = this.hierarchy.replaceAll(/'/g, '"');
      temp = temp.replaceAll(/None/g, '"None"');
      temp = temp.replaceAll(/True/g, '"true"');
      temp = temp.replaceAll(/False/g, '"false"');
      return JSON.parse(temp);
    }
  },
  data: function () {
    return {
      i18nStrings: {
        close: gettext("Close"),
        search: gettext("Search"),
        deleteAll: gettext("Delete all"),
      }
    }
  },
  methods: {
    deleteAllSearchFilters: function () {
      this.$refs.searchForm.$refs.tagsFilter.unselectAllTags(true);
    },
  }
};

</script>
