<template>
    <div>
        <div id="cookieBannerV2" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="cookieBannerV2" aria-hidden="true" :class="{ show: shown }" :style="shown ? 'display: block; z-index: 1030;' : 'display: none;'">
            <div class="modal-dialog" role="document">
                <div class="modal-content text-center">
                    <div class="modal-body medium mt-4">
                        <p><b>{{ t.aboutCookies }}</b></p>
                        <p class="medium">{{ t.aboutCookiesDescription }}</p>
                    </div>
                    <div class="modal-footer d-block">
                        <button type="button" class="btn btn-primary" @click="acceptAll">{{ t.acceptAll }}</button>
                        <br>
                        <button type="button" class="btn btn-link skokka-text" @click="manageCookies">{{ t.manageCookies }}</button>
                        <p class="small" v-html="t.moreInfos" />
                    </div>
                </div>
            </div>
        </div>
        <div id="cookieBannerV2ManageCookie" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="cookieBannerV2ManageCookie" :class="{ show: showCookieManager }" :style="showCookieManager ? 'display: block;' : 'display: none;'" >
            <div class="modal-dialog" role="document">
                <div class="modal-content text-center">
                    <div class="modal-body mt-4">
                        <small>
                            <p>{{ t.personalizeCookies }}</p>
                            <p v-html="t.moreInfos" />
                        </small>
                        <ul class="list-group text-left txt_medium">
                            <li class="list-group-item">
                                <div class="row">
                                    <div class="col pl-2 pr-0">
                                        <a
                                            data-toggle="collapse"
                                            href="#collapseStrictlyNecessary"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="collapseStrictlyNecessary"
                                        >
                                            {{ t.strictlyNecessary }}
                                            <i class="arrow-r"></i>
                                        </a>
                                    </div>
                                    <div class="small text-right pr-2"><b>{{ t.alwaysActive }}</b></div>
                                </div>
                                <p class="small mb-0 pt-3 collapse" id="collapseStrictlyNecessary">
                                    {{ t.strictlyNecessaryDescription }}
                                </p>
                            </li>
                            <li class="list-group-item justify-content-between align-items-center">
                                <div class="row">
                                    <div class="col pl-2 pr-0 pt-2">
                                        <a
                                            data-toggle="collapse"
                                            href="#collapseAnalytical"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="collapseAnalytical"
                                        >
                                            {{ t.analyticalPerformance }} <i class="arrow-r"></i>
                                        </a>
                                    </div>
                                    <label class="switch switch-left-right float-right">
                                        <input class="switch-input" type="checkbox" v-model="hasPerformanceCookie" />
                                        <span class="switch-label" />
                                        <span class="switch-handle" />
                                    </label>
                                    <p class="small mb-0 p-3 collapse" id="collapseAnalytical">{{ t.analyticalPerformanceDescription }}</p>
                                </div>
                            </li>
                            <li v-if="showFunctionalCookies" class="list-group-item justify-content-between align-items-center">
                                <div class="row">
                                    <div class="col pl-2 pr-0 pt-2">
                                        <a
                                            data-toggle="collapse"
                                            href="#collapseFunctional"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="collapseFunctional"
                                        >
                                            {{ t.functional }} <i class="arrow-r"></i>
                                        </a>
                                    </div>
                                    <label class="switch switch-left-right float-right">
                                        <input class="switch-input" type="checkbox" v-model="hasFunctionalCookie" />
                                        <span class="switch-label" />
                                        <span class="switch-handle" />
                                    </label>
                                    <p class="small mb-0 p-3 collapse" id="collapseFunctional">{{ t.functionalDescription }}</p>
                                </div>
                            </li>
                            <li class="list-group-item justify-content-between align-items-center">
                                <div class="row">
                                    <div class="col pl-2 pr-0 pt-2">
                                        <a
                                            data-toggle="collapse"
                                            href="#collapseAdvertising"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="collapseAdvertising"
                                        >
                                            {{ t.advertising }} <i class="arrow-r"></i>
                                        </a>
                                    </div>
                                    <label class="switch switch-left-right float-right">
                                        <input class="switch-input" type="checkbox" v-model="hasAdvertisingCookie">
                                        <span class="switch-label"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                    <div class="collapse" id="collapseAdvertising">
                                        <p class="small mb-0 p-3"> {{ t.advertisingDescription }}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-footer borderless d-block">
                        <button type="button" class="btn btn-primary" @click="acceptAll">{{ t.acceptAll }}</button>
                        <br>
                        <button type="button" class="btn btn-outline-primary" @click="acceptMyPreferences">{{ t.acceptMyPreferences }}</button>
                        <p class="small pt-3">
                            <a href="#" @click.prevent="rejectAllNonNecessary">{{ t.rejectAllNonNecesary }}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Translations from "./insert-me-to-translate.vue";

import {
    getLightboxName,
    privacyCookieName,
    performanceCookieName,
    advertisingCookieName,
    functionalCookieName,
} from '../utils';

export default {
    props: {
        privacyCookie: {
            type: Boolean,
            default: false,
        },
        cookieUrl: {
            type: RegExp,
            default: '',
        },
        privacyUrl: {
            type: RegExp,
            default: '',
        },
        // This props is helpful to open banner-cookies-v2 with cookie manager opened
        openCookieManager: {
            type: Boolean,
            default: false,
        },
        showCookieBanner: {
            type: Boolean,
            default: true,
        },
        showFunctionalCookies: {
          type: Boolean,
          default: false
        }
    },
    data() {
        let moreInfos = gettext('For more information, please visit our <a data-href="%(cookie_url)s" target="_blank">Cookie Notice</a> and our <a data-href="%(privacy_url)s" target="_blank">Privacy Notice</a>')

        moreInfos = moreInfos.replace('%(cookie_url)s', this.cookieUrl);
        moreInfos = moreInfos.replace('%(privacy_url)s', this.privacyUrl);

        return {
            lightboxName: null,
            showCookieManager: this.openCookieManager || false,
            hasPrivacyCookie: !!this.$cookies.get(privacyCookieName) || false,
            hasPerformanceCookie: !!this.$cookies.get(performanceCookieName) || false,
            hasAdvertisingCookie:  !!this.$cookies.get(advertisingCookieName) || false,
            hasFunctionalCookie:  !!this.$cookies.get(functionalCookieName) || false,

            // Translations
            t: {
                aboutCookies: gettext("About cookies on this website"),
                aboutCookiesDescription: gettext("We use cookies to optimize the performance of this website and provide you with a better user experience in a personalized way. In the options you can choose your preferences for the use of cookies:"),
                acceptAll: gettext("Accept all"),
                manageCookies: gettext("Manage cookies"),
                moreInfos,
                personalizeCookies: gettext("You can personalise the cookies we use by providing your consent to enable their use by us by exercising your choice using the buttons below."),
                strictlyNecessary: gettext("Strictly Necessary cookies"),
                strictlyNecessaryDescription: gettext("These cookies are necessary for the website to function and cannot be switched off in our systems. They are typically set only in response to actions you take that correspond to a request for services, such as setting your privacy preferences, logging in, or filling in forms. These cookies can be disabled through your browser settings. If you choose to do so, some parts of the website will not work. These cookies do not store any personally identifiable information."),
                alwaysActive: gettext("Always active"),
                analyticalPerformance: gettext("Analytical/Performance Cookies"),
                analyticalPerformanceDescription: gettext("These cookies help us understand how visitors interact with the Pages and our website by providing information about the areas visited, the length of time they visited the website, and any problems encountered, such as error messages."),
                advertising: gettext("Advertising Cookies"),
                advertisingDescription: gettext("These cookies are used to provide more relevant content of interest to the User. They can be used to display more targeted advertising or limit the number of ads served."),
                acceptMyPreferences: gettext("Accept my Preferences"),
                rejectAllNonNecesary: gettext("Reject all non-necessary cookies"),
                functional: gettext("Functional Cookies"),
                functionalDescription: gettext("These cookies help us understand how visitors interact with the Pages and our website by providing information about the areas visited, the length of time they visited the website, and any problems encountered, such as error messages."),
            }
        };
    },
    computed: {
        shown() {
            return this.$store.getters['ui/lightboxManager/lightboxIsShown'](this.lightboxName);
        }
    },
    mounted() {
        this.lightboxName = getLightboxName({ el: this.$el });

        if (!this.showCookieBanner || this.hasPrivacyCookie) {
           this.$store.dispatch("ui/lightboxManager/removeAndHide", this.lightboxName);
        }
    },
    methods: {
        acceptAll() {
            this.$cookies.set(privacyCookieName, '1', '1d', '/', locations.cookie_domain);
            this.$root.$emit('setPrivacyCookie', true);
            this.closeCookieManager();
            this.$store.dispatch("ui/lightboxManager/hide");
        },
        acceptMyPreferences() {
            this.$cookies.set(privacyCookieName, '1', '1d', '/', locations.cookie_domain);

            this.$root.$emit('setPrivacyCookie', true);
            this.closeCookieManager();
            this.$store.dispatch("ui/lightboxManager/hide");
        },
        rejectAllNonNecessary() {
            this.$cookies.set(privacyCookieName, '1', '1d', '/', locations.cookie_domain);
            this.$root.$emit('setPrivacyCookie', true);
            this.closeCookieManager();
            this.$store.dispatch("ui/lightboxManager/hide");
        },
        manageCookies() {
            this.showCookieManager = !this.showCookieManager
        },
        closeCookieManager() {
            this.showCookieManager = false;
        },
    }
}
</script>
