/**
 * Calculate time remaining until a specific end date
 * @param {string|Date} endDate - The target end date
 * @returns {Object} Object containing total milliseconds and formatted hours, minutes, seconds
 */
export function calculateTimeLeft(endDate) {
    if (!endDate || new Date(endDate).toString() === "Invalid Date") {
      return { total: 0, hours: '00', minutes: '00', seconds: '00' };
    }

    const now = new Date().getTime();
    const endTime = new Date(endDate).getTime();
    const total = endTime - now;

    if (total < 0) {
      return { total: 0, hours: '00', minutes: '00', seconds: '00' };
    }

    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60));
    
    return { total, hours, minutes, seconds };
}
  
/**
 * Start a countdown timer that updates at 1 second intervals
 * @param {string|Date} endDate - The target end date
 * @param {Function} onUpdate - Callback function to receive updated time values
 * @param {Function} [onComplete] - Optional callback when countdown reaches zero
 * @returns {number} Timer ID that can be used to clear the interval
 */
export function startCountdown({ endDate, onUpdate, onComplete, interval = 1000 }) {
  // Initial calculation
  let timeLeft = calculateTimeLeft(endDate);
  onUpdate(timeLeft);
  
  // Set up interval
  const timer = setInterval(() => {
    timeLeft = calculateTimeLeft(endDate);
    onUpdate(timeLeft);
    
    if (timeLeft.total <= 0) {
      clearInterval(timer);
      if (onComplete) {
        onComplete();
      }
    }
  }, interval);
  
  return {
    timerId: timer,
    stop: () => clearInterval(timer)
  };
}
  