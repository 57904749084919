export * from './lightbox'
export * from './cookies'
export * from './uuid'
export * from './validators'
export * from './promotion-countdown'

export function removeQueryParamUtility(param) {
    if (!param) {
        console.warn('No param provided');
        return;
    }

    if (typeof window !== 'undefined' && window?.location?.search) {
        const url = new URL(window.location);

        if (url.searchParams.has(param)) {
            url.searchParams.delete(param);
            window.history.pushState({}, '', url); // Update the URL without reloading the page
        } else {
            console.warn(`Param ${param} not found in the query string.`);
        }
    }
}

/**
 * Handles click events on elements with the `data-href` attribute.
 * - If `data-disable-after-click` is false or undefined, navigates to `data-href`.
 * - If the element has `target="_blank"`, opens the link in a new tab and focuses on it.
 * - If `data-disable-after-click` is false, it updates the attribute to prevent multiple clicks.
 *
 * @param {Event} event - The click event object.
 */
export function handleDataHrefClick(event) {
  let target = event.target.closest('*[data-href]');
  if (!target) return;

  const href = target.dataset.href;
  if (!href) return;

  const hasDisableAttribute = target.hasAttribute('data-disable-after-click');
  const disableAttrValue = target.getAttribute('data-disable-after-click') || 'false';
  const shouldNavigate = !hasDisableAttribute || disableAttrValue === 'false';

  if (shouldNavigate) {
    if (target.getAttribute('target') === '_blank') {
      let win = window.open(href, '_blank');
      if (win) {
        win.focus();
      }
    } else {
      window.location = href;
    }
  }

  if (hasDisableAttribute && disableAttrValue === 'false') {
    target.dataset.disableAfterClick = 'true';
  }
}
