<template>
    <div class="lightboxManager">
        <slot></slot>
    </div>
</template>

<script>
import {
    addUserInteractionEvents,
    blockScroll,
    releaseScroll,
} from '../utils';

/**
    Since slot components are mounted before the lightbox manager component,
    we need to remove the empty DOM nodes when this component is mounted.
    This trick allows us to preserve the logic running inside each mounted hook in the lightbox component.
*/
export default {
    props: {
        queue: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            nodesToRemove: [],
            unwatch: null,
        }
    },
    created() {
        const templates = this.$slots.default.filter(slot => slot?.tag == 'div' && slot?.data?.attrs?.name);

        templates.forEach((vnode) => {
            const name = vnode.data.attrs['name'];
            const nameNotRegistered = this.$store.state.ui.lightboxManager.registeredModels.indexOf(name) == -1;
            const isTemplateNotEmpty = !!(vnode?.children?.[0])

            if (isTemplateNotEmpty) {
                if (nameNotRegistered) {
                    this.$store.dispatch('ui/lightboxManager/register', name);
                }
                else {
                    console.warn(`lightbox with name:${name} already registered`);
                    this.nodesToRemove.push(vnode);
                }
            }
            else {
                this.nodesToRemove.push(vnode);
            }
        });

        // Initialize the queue system
        this.$store.dispatch("ui/lightboxManager/show", this.queue ? this.queue.split(",").map(name => name?.trim()) : []);

        // Control the scroll block behavior when lightboxes are shown or hidden
        this.unwatch = this.$store.watch((state) => state.ui.lightboxManager.current, (newValue, _oldValue) => newValue ? blockScroll() : releaseScroll());
    },
    mounted() {
        this.nodesToRemove.forEach((vnode) => {
            vnode.elm && vnode.elm.remove();
        });

        // Ensure event listeners are attached after the page content is fully loaded:
        window.addEventListener('load', () => {
            addUserInteractionEvents(() => this.$store.dispatch('ui/lightboxManager/userInteractionDetected'));
        });
    },
    beforeDestroy() {
        this.unwatch();
    },
}
</script>
