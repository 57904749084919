var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-3" }, [
    _c("div", { staticClass: "d-inline", on: { click: _vm.userAction } }, [
      _vm.text7
        ? _c(
            "a",
            {
              attrs: {
                "data-toggle": "collapse",
                href: "#collapseHeaderText",
                role: "button",
                "aria-expanded": "true",
                "aria-controls": "collapseHeaderText",
              },
            },
            [
              _c("h1", {
                staticClass: "item-title d-inline text-dark",
                domProps: { innerHTML: _vm._s(_vm.text1) },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]
          )
        : _c("h1", {
            staticClass: "item-title d-inline text-dark",
            domProps: { innerHTML: _vm._s(_vm.text1) },
          }),
    ]),
    _vm._v(" "),
    _vm.text7
      ? _c(
          "div",
          {
            staticClass:
              "list-info mt-3 mb-3 card shadow border-0 collapse show txt_seo_2",
            attrs: { id: "collapseHeaderText" },
          },
          [
            _c("p", {
              staticClass: "mb-0 p-4",
              domProps: { innerHTML: _vm._s(_vm.text7) },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-inline" }, [
      _c("i", { staticClass: "small fa fa-chevron-right text-dark" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }