export default {

    methods: {
        contactButtonAnalytics(isDetailView, postPublicId, contactType) {
            try {
                const analyticsSelector = isDetailView ? "#post-analytics-data" : "#data-layer-items";
                const analytics = document.querySelector(analyticsSelector);

                if (!analytics || !analytics.textContent.trim()) {
                    console.error('Analytics content is empty, undefined, or invalid.');
                    return;
                }

                const content = analytics.textContent.trim();
                let eventData;

                try {
                    eventData = JSON.parse(content);
                } catch (error) {
                    console.error('Error parsing JSON:', error.message);
                    return;
                }

                if (isDetailView) {
                    eventData["event"] = "contact";
                    eventData["communication"] = contactType;
                    dataLayer.push(eventData);
                } else {
                    const analyticsData = eventData.find(e => e.event === 'view_post_list');

                    if (!analyticsData) {
                        console.error('No "view_post_list" event found.');
                        return;
                    }

                    const postData = analyticsData.posts.find(p => p.post_id === postPublicId);

                    if (!postData) {
                        console.error(`No post found with post_id: ${postPublicId}`);
                        return;
                    }

                    if(postData.position) {
                        delete postData.position;
                    }

                    const newEventData = {
                        event: 'contact',
                        communication: contactType,
                        city: analyticsData['city'],
                        posts: [postData],
                    };

                    if (analyticsData['region']) {
                        newEventData['region'] = analyticsData['region'];
                    }
        
                    dataLayer.push(newEventData);
                }
            } catch (e) {
                console.error('Unexpected error:', e);
            }
        },
    },
}
