import '../vendors/bootstrap.min.css'
import '~templates/css/style.css';
import '~templates/css/icomoon/style.css';
import '~templates/css/private-area.css';
import '~templates/css/2023.css';
import '~templates/css/new-listing.css';
import '../vendors/all.min.css'
import 'intersection-observer';
import 'promise-polyfill/src/polyfill';
import "core-js/stable";
import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';
import VueSkokka from './libs/vue-skokka';
import "./directives";
import { autorenewal, order, coupons, ui } from "./store/modules";
import VLazyImage from "v-lazy-image/v2";
import VueCompositionAPI from '@vue/composition-api';
import {
    privacyCookieName,
    performanceCookieName,
    advertisingCookieName,
    removeQueryParamUtility,
    handleDataHrefClick,
} from './utils';

Vue.use(VueCookies);
Vue.use(VueSkokka);
Vue.use(Vuex);
Vue.use(VueCompositionAPI)
Vue.component('v-lazy-image', VLazyImage);  // register component globally

//polyfill requestAnimationFrame
window.requestAnimationFrame = window.requestAnimationFrame || function (callback) {
    return window.setTimeout(callback, 1000 / 60);
};

window.cancelAnimationFrame = window.cancelAnimationFrame || clearTimeout;
//polyfill NodeList foreach
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

//polyfill console
window.console = window.console || {
    log: function () {
    }, debug: function () {
    }, info: function () {
    }, error: function () {
    }
};

const store = new Vuex.Store({
    modules: {
        order,
        ui,
        coupons,
        autorenewal,
    }
})

window.app = new Vue({
    el: '#app',
    store: store,
    delimiters: ['[[', ']]'],
    data: function () {
        return {
            privacyCookie: false,
            breadcrumbsDropdownObserver:null,
        }
    },
    created: function () {
        this.isMobile = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/.test(window.navigator.userAgent);
    },
    mounted: function () {
      // Handles click events on elements with the `data-href` attribute.
      document.body.addEventListener('click', handleDataHrefClick);


        function updateListingStyles() {
            const listingsArray = document.querySelectorAll('.item-card');
            const titleSizes = [];

            listingsArray.forEach((el) => {
                const title = el.querySelector('.listing-title');
                if (!title) {
                    titleSizes.push(1); // Default size
                    return;
                }
                
                const titleStyle = getComputedStyle(title);
                titleSizes.push(Math.round(parseFloat(titleStyle.height) / parseFloat(titleStyle.lineHeight)));
            });

            listingsArray.forEach((el, index) => {
                const description = el.querySelector('.item-description');

                if (!description) {
                    return;
                }

                const currentTitleSize = titleSizes[index];

                let newLineLimit;
                switch (currentTitleSize) {
                    case 1:
                        newLineLimit = 6;
                        break;
                    case 2:
                        newLineLimit = 5;
                        break;
                    case 3:
                        newLineLimit = 4;
                        break;
                }
                description.setAttribute('class',`item-description line-clamp line-clamp-${newLineLimit}`);
            });
        }

        addEventListener('resize', updateListingStyles);

        // click on detail items
        $('body').on('click', '.item-card', function (event){
            const target = $(event.target);
            const domTarget = target[0];
            const card = domTarget.closest('.item-card');

            if (target.hasClass('slide-action') || target.hasClass('btncontact')){
                return;
            }

            if(domTarget.tagName == "A" && domTarget.getAttribute('href').indexOf('?pck') == -1 ){
                event.preventDefault();
            } else if(domTarget.tagName == "A"){
                return;
            }

            const linkNode = card.querySelector('a[href][data-pck]');
            const anchor = $(this).find('a[data-pck]')

            if (anchor && linkNode) {
                if(target.data('pck')) {
                    event.preventDefault();
                }
                let href = anchor.attr('href');
                let pck = anchor.data('pck');

                if (href.includes('?')) {
                  href = `${href}&pck=${pck}`;
                } else {
                    href = `${href}?pck=${pck}`;
                }

                linkNode.setAttribute('href',href);
                linkNode.click();
                window.location.href = href;
            }
        });

        function do_nothing() {
            return false;
        }

        // prevent a second click for 10 seconds. :)
        $('body').on('click', '.prevent_doubleclick', function () {
            var elem = $(this)
            elem.click(do_nothing);
            elem.addClass("disable_click_link");

            setTimeout(function () {
                elem.removeClass("disable_click_link");
                elem.off('click', do_nothing);
            }, 10000);
        })


        // hamburger menu
        $('.third-button').on('click', function () {
            $('.animated-icon3').toggleClass('open');
        });

        document.addEventListener('readystatechange', (event) => {
            if (document.readyState === 'complete') {
                this.removeLoadingClass();

                updateListingStyles();

                if (locations.privacy_cookie_country && !this.$cookies.get(privacyCookieName)) {
                    this.$skokka.deleteFunctionalCookies()
                }
                if (!this.$cookies.get(performanceCookieName)) {
                    this.$skokka.deletePerformanceCookies()
                }
                if (!this.$cookies.get(advertisingCookieName)) {
                    this.$skokka.deleteAdvertisingCookies()
                }
            }
        });


        // clear modal_verify_identity_shown cookie if logged_cookie is not set
        const loggedCookie = this.$cookies.get('logged_cookie');

        if (!loggedCookie) {
            this.$cookies.remove('verify_identity_shown', '/', locations.cookie_domain);
            this.$cookies.remove('show_fraud_alert_lightbox', '/', locations.cookie_domain)
        }

        /* end disclaimer */
        this.$root.$on("setPrivacyCookie", function (value) {
            this.privacyCookie = value
        });

        if (document.readyState === 'complete') {
            this.removeLoadingClass();

            if (locations.privacy_cookie_country && !this.$cookies.get(privacyCookieName)) {
                this.$skokka.deleteFunctionalCookies()
            }
            if (!this.$cookies.get(performanceCookieName)) {
                this.$skokka.deletePerformanceCookies()
            }
            if (!this.$cookies.get(advertisingCookieName)) {
                this.$skokka.deleteAdvertisingCookies()
            }
        }

        setTimeout(this.removeLoadingClass, 3000); // some old browser does not support readystatechange event

        this.runBreadcrumbsDropdownObserver();
    },
    beforeDestroy() {
      if (this.breadcrumbsDropdownObserver) {
        this.breadcrumbsDropdownObserver.disconnect();
      }
      this.hideBreadcrumbsBackdrop();
    },
    methods: {
        removeLoadingClass: function () {
            $('body').removeClass('loading');
        },
        activateSearchDialog: function () {
            this.$store.state.ui.searchModal.showSearchModal = true;
        },
        removeQueryParam(param) {
            removeQueryParamUtility(param)
        },
      //breadcrumbs backdrop logic which observes show/hide bootstrap's dropdown status
      runBreadcrumbsDropdownObserver() {
        this.$nextTick(() => {
          const breadcrumbsDropdownMenu = document.getElementById('breadcrumbs-dropdown');
          if (breadcrumbsDropdownMenu) {
            // Create a MutationObserver if it doesn't exist to watch for class changes on the dropdown menu.
            if (!this.breadcrumbsDropdownObserver) {
              this.breadcrumbsDropdownObserver = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                  if (mutation.attributeName === 'class') {
                    if (breadcrumbsDropdownMenu.classList.contains('show')) {
                      this.showBreadcrumbsBackdrop();
                    } else {
                      this.hideBreadcrumbsBackdrop();
                    }
                  }
                });
              });

              // Start observing the breadcrumbsDropdownMenu element
              this.breadcrumbsDropdownObserver.observe(breadcrumbsDropdownMenu, {
                attributes: true
              });
            }
          }
        });
      },
        showBreadcrumbsBackdrop() {
          const breadcrumbsDropdownBackdrop = document.getElementById('breadcrumbs-dropdown-backdrop');
          if (breadcrumbsDropdownBackdrop) {
            const header = document.getElementById('main-header');
            if (header) {
              header.classList.add('z-index-1000');
            }

            breadcrumbsDropdownBackdrop.classList.add('show');
            // Clicking the breadcrumbs backdrop simulates a click on the toggle to close the dropdown.
            breadcrumbsDropdownBackdrop.addEventListener('click', () => {
              const toggleButton = document.getElementById('breadcrumbs-dropdown-toggle');
              if (toggleButton) {
                toggleButton.click();
              }
            });
            if (window.matchMedia?.("(max-width: 767px)")?.matches) {
                // prevent body scroll when backdrop is visible
                document.body.classList.add('overflow-hidden');
            }
          }
        },
      hideBreadcrumbsBackdrop() {
        const breadcrumbsDropdownBackdrop = document.getElementById('breadcrumbs-dropdown-backdrop');
        if (breadcrumbsDropdownBackdrop) {
          const header = document.getElementById('main-header');
          breadcrumbsDropdownBackdrop.removeEventListener('click', this.handleClick);
          breadcrumbsDropdownBackdrop.classList.remove('show');
          if (header) {
            header.classList.remove('z-index-1000');
          }

          // allow body scroll when backdrop is hidden
          document.body.classList.remove('overflow-hidden');
        }
      }
    },

    components: {
        // main chunk
        Disclaimer: () => import(/* webpackMode: "eager" */ './components/disclaimer'),
        // ListingItem: () => import(/* webpackMode: "eager" */ './components/listing-item'),
        Pagination: () => import(/* webpackMode: "eager" */ './components/pagination'),
        SearchModal: () => import(/* webpackMode: "eager" */ './components/search/search-modal'),
        PhoneButton: () => import(/* webpackMode: "eager" */ './components/phone-button'),
        WhatsappButton: () => import(/* webpackMode: "eager" */ './components/whatsapp-button'),
        RelatedPosts: () => import(/* webpackMode: "eager" */ './components/related-posts'),
        CardsPostsList: ()=> import (/* webpackMode: "eager" */ './components/post/cards-posts-list.vue'),
        LightboxVm18: () => import(/* webpackMode: "eager" */ './components/lightbox-vm18'),
        BannerCookies: () => import(/* webpackMode: "eager" */ './components/banner-cookies'),
        BannerCookiesV2: () => import(/* webpackMode: "eager" */ './components/banner-cookies-v2'),
        LightboxManager: ()=> import(/* webpackMode: "eager" */ './components/lightbox-manager'),
        Lightbox: () => import(/* webpackMode: "eager" */ './components/ui/lightbox'),
        Loader: () => import(/* webpackMode: "eager" */ './components/ui/loader'),
        LightboxBlock: () => import(/* webpackMode: "eager" */ './components/lightbox-block'),
        TagsSectionsDetail: () => import(/* webpackMode: "eager" */ './components/post/tags-sections-detail.vue'),
        AdvancedSearch: () => import(/* webpackMode: "eager" */ './components/advanced-search.vue'),
        SeoHeader: () => import(/* webpackMode: "eager" */ './components/seo-header'),
        ListingStories: () => import(/* webpackMode: "eager" */ './components/listing-stories'),
        CarouselText: () => import( /* webpackMode: "eager" */  './components/carousel-text.vue'),
        UiButton: () => import( /* webpackMode: "eager" */  './components/ui/ui-button.vue'),
        UiMessage: () => import( /* webpackMode: "eager" */  './components/ui/ui-message.vue'),
        TesterFrontendUserActionButtons: () => import(/* webpackChunkName: "eager" */ './components/tester-frontend-user-action-buttons.vue'),
        TesterProfileVerificationsActionButtons: () => import(/* webpackChunkName: "eager" */ './components/tester-profile-verifications-action-buttons.vue'),
        // home page lightboxes
        LightboxVideocall: () => import(/* webpackChunkName: "chunck-lightboxes-home-page" */ './components/lightbox-videocall'),
        LightboxDeletedAccount: () => import(/* webpackChunkName: "chunck-lightboxes-home-page" */ './components/lightbox-deleted-account'),

        // dynamic imports in non-protected pages
        ReportAdForm: () => import(/* webpackChunkName: "chunck-report-ad-form" */ './components/report-ad-form'),
        ReplyAdForm: () => import(/* webpackChunkName: "chunck-reply-ad-form" */ './components/reply-ad-form'),
        SignupForm: () => import(/* webpackChunkName: "chunck-signup-form" */ './components/signup-form'),
        ManageYourAdsForm: () => import(/* webpackChunkName: "chunck-manage-your-ads-form" */ './components/manage-your-ads-form'),
        LightboxManageCookies: () => import(/* webpackChunkName: "chunck-lightbox-manage-cookies" */ './components/lightbox-manage-cookies'),
        UnsubscribeForm: () => import(/* webpackChunkName: "chunck-unsubscribe-form" */ './components/unsubscribe-form'),

        // private area aggregated chunk
        PrivateArea: () => import(/* webpackChunkName: "chunck-private-area"*/ "./components/private-area"),
        LoginForm: () => import(/* webpackChunkName: "chunck-private-area"*/ './components/login-form'),
        PasswordRecoverForm: () => import(/* webpackChunkName: "chunck-private-area" */ './components/password-recover-form'),
        PasswordResetForm: () =>import(/* webpackChunkName: "chunck-private-area" */ './components/password-reset-form'),
        PasswordChange: () => import(/* webpackChunkName: "chunck-private-area"*/ './components/password-change'),
        Packages: () => import(/* webpackChunkName: "chunck-private-area"*/ './components/packages'),
        TransactionsHistory: () => import(/* webpackChunkName: "chunck-private-area"*/ './components/transactions-history'),
        AddEmail: () => import(/* webpackChunkName: "chunck-private-area" */ './components/add-email'),
        TermsConditions: () => import(/* webpackChunkName: "chunck-private-area" */ './components/terms-conditions'),
        DeleteAccount: () => import(/* webpackChunkName: "chunck-private-area" */ './components/delete-account'),
        VerifiedContact: () => import(/* webpackChunkName: "chunck-private-area" */ './components/verified-contact'),
        AccountFilterForm: () => import(/* webpackChunkName: "chunck-private-area" */ './components/account-filter-form'),
        MarketingFlag: () => import(/* webpackChunkName: "chunck-private-area" */ './components/marketing-flag'),
        ShareOnlineAd: () => import(/* webpackChunkName: "chunck-private-area" */ './components/share-online-ad'),
        PromotedPostEditCountdown: () => import(/* webpackChunkName: "chunck-private-area" */ './components/promoted-post-edit-countdown'),
        PostActionButtons: () => import(/* webpackChunkName: "chunck-private-area" */ './components/post-action-buttons'),
        RedeemVoucher: () => import(/* webpackChunkName: "chunck-private-area" */ './components/account/redeem_voucher/redeem-voucher.vue'),
        AddCoupon: () => import(/* webpackChunkName: "chunck-private-area" */ './components/account/coupon/add-coupon'),
        Coupon: () => import(/* webpackChunkName: "chunck-private-area" */ './components/account/coupon/coupon'),
        UseCoupon: () => import(/* webpackChunkName: "chunck-private-area" */ './components/account/coupon/use-coupon'),
        CouponsList: () => import(/* webpackChunkName: "chunck-private-area" */ './components/account/coupon/coupons-list'),
        AutoRenewal: () => import(/* webpackChunkName: "chunck-private-area" */ './components/account/auto-renewal/auto-renewal'),
        LightboxAutoRenewal: () => import(/* webpackChunkName: "chunck-private-area" */ './components/account/auto-renewal/lightbox-auto-renewal'),
        LightboxAutoRenewalLearnMore: () => import(/* webpackChunkName: "chunck-private-area" */ './components/account/auto-renewal/lightbox-auto-renewal-learn-more'),
        LightboxInsertPhoneNumber: () => import(/* webpackChunkName: "chunk-post-upsert" */ './components/lightbox-insert-phone-number'),
        SkokkaButton: () => import(/* webpackChunkName: "chunck-private-area" */ './components/ui/skokka-button.vue'),

        PostForm: () => import(/* webpackChunkName: "chunck-post-insert" */ './components/post-form'),
        PostInsertImages: () => import(/* webpackChunkName: "chunck-post-insert-images" */ './components/post-insert-images'),

        PostPromoteForm: () => import(/* webpackChunkName: "chunck-post-promote-form" */ './components/post-promote-form'),
        AddOns: () => import(/* webpackChunkName: "chunck-post-promote-form" */ './components/add_ons/add-ons'),
        AddOn: () => import(/* webpackChunkName: "chunck-post-promote-form" */ './components/add_ons/add-on'),
        Order: () => import(/* webpackChunkName: "chunck-order" */ './components/order/order'),
        WaitingPayment: () =>import(/* webpackChunkName: "chunck-waiting-payment"*/ './components/order/waiting-payment'),

        LightboxDuplicatePost: () => import(/* webpackChunkName: "chunck-lightboxes-private-area"*/ './components/lightbox-duplicate-post'),
        LightboxSuspendPost: () => import(/* webpackChunkName: "chunck-lightboxes-private-area"*/ './components/lightbox-suspend-post'),
        LightboxDeletePost: () => import(/* webpackChunkName: "chunck-lightboxes-private-area"*/ './components/lightbox-delete-post'),
        LightboxDeletePromotion: () => import(/* webpackChunkName: "chunck-lightboxes-private-area"*/ './components/lightbox-delete-promotion'),
        LightboxFraudAlert: () => import(/* webpackChunkName: "chunck-lightboxes-private-area"*/ './components/lightbox-fraud-alert'),
        LightboxCreateProfile: () => import(/* webpackChunkName: "chunk-lightboxes-private-area" */ './components/lightbox-create-profile.vue'),
        LightboxAddonsLogin: () => import(/* webpackChunkName: "chunk-lightboxes-addons" */ './components/lightbox-addons-login.vue'),
        LightboxPromotionJustLoggedIn: () => import(/* webpackChunkName: "chunk-lightboxes-promotion-just-logged-in" */ './components/lightbox-promotion-just-logged-in.vue'),
        LightboxPayoraLogin: () => import(/* webpackChunkName: "chunk-lightboxes-payora" */ './components/lightbox-payora-login.vue'),
        LightboxVerificationDetected: () => import(/* webpackChunkName: "chunk-lightboxes-private-area" */ './components/lightbox-verification-detected.vue'),
        ModalVerifyIdentityYoti: ()=> import (/* webpackChunkName: "chunk-yoti-modal-verify" */ './components/yoti/modal-verify-identity.vue'),
        ModalTooMuchAttempts: ()=> import (/* webpackChunkName: "chunk-yoti-modal-too-much-attempts" */ './components/yoti/modal-too-much-attempts.vue'),
        
        DashboardVerifyIdentity: ()=> import (/* webpackChunkName: "chunk-yoti-dashboard-verify" */ './components/yoti/dashboard-verify-identity.vue'),
        DashboardVerifiedIdentity: ()=> import (/* webpackChunkName: "chunk-yoti-dashboard-verified" */ './components/yoti/dashboard-verified-identity.vue'),
        DashboardVerificationStatus: ()=>import (/* webpackChunkName: "chunk-yoti-dashboard-status" */ './components/yoti/dashboard-verification-status.vue'),

        DashboardCard: ()=> import (/* webpackChunkName: "chunk-dashboard-card" */ './components/cards/dashboard-card.vue'),
        DashboardCardInfo: ()=> import (/* webpackChunkName: "chunk-cards-info" */ './components/cards/dashboard-card-info.vue'),
        ProductCard: ()=> import (/* webpackChunkName: "chunk-cards-product" */ './components/cards/product-card.vue'),
        DashboardCardVideo360: () => import (/* webpackChunkName: "chunk-cards-video-360" */ './components/cards/dashboard-card-video-360.vue'),

        ProfileOnboarding: ()=> import (/* webpackChunkName: "profile-onboarding" */ './components/account/profile/profile-onboarding.vue'),
        MultiprofilePanel: () => import(/* webpackChunkName: "chunk-multiprofile-panel" */ './components/account/profile/multiprofile-panel.vue'),
        CurrencyInput: () => import(/* webpackChunkName: "chunk-currency-input" */ './components/ui/currency-input.vue'),
        UiAlert: () => import(/* webpackChunkName: "chunk-ui-alert" */ './components/ui/ui-alert.vue'),
        UiCard: ()=> import (/* webpackChunkName: "chunk-ui-card" */ './components/ui/ui-card.vue'),
        UiModal: ()=> import (/* webpackChunkName: "chunk-ui-modal" */ './components/ui/ui-modal.vue'),
        
        ModalComponent: () => import(/* webpackChunkName: "chunk-modal-component" */ './components/modal-component.vue'),
        RenderHtml: () => import(/* webpackChunkName: "chunk-skokka-render-html" */ './components/render-html.vue'),
        VerticallyHideableHtml: () => import(/* webpackChunkName: "chunk-skokka-vertically-hideable-html" */ './components/vertically-hideable-html.vue'),
        AsyncText: () => import(/* webpackChunkName: "chunk-skokka-async-text" */ './components/async-text.vue'),
        SkokkaGallery: () => import(/* webpackChunkName: "chunk-skokka-gallery" */ './components/post/gallery/gallery.vue'),

        PostPromoteManager: () => import(/* webpackChunkName: "chunk-profile-post-promote-manager" */"./components/post/promote/post-promote-manager.vue"),
        VerificationsNextButton: () => import(/* webpackChunkName: "chunk-profile-verifications-next-button" */"./components/cards/verifications-next-button.vue"),

        CreateSimilar: () => import(/* webpackChunkName: "chunk-create-similar" */"./components/create-similar.vue"),

        Video360Uploader: () => import(/* webpackChunkName: "chunk-profile-video-uploader" */"./components/video-360/video-360-uploader.vue"),
        Video360UploaderResumable: () => import(/* webpackChunkName: "chunk-profile-video-uploader-resumable" */"./components/video-360/video-360-uploader-resumable.vue"),

        MediaUploader: () => import(/* webpackChunkName: "chunk-profile-media-uploader" */"./components/uploader/media-uploader.vue"),

        ProfileHeader: () => import(/* webpackChunkName: "chunk-profile-header" */"./components/account/profile/profile-header.vue"),
        ProfileDetails: () => import(/* webpackChunkName: "chunk-profile-details" */"./components/account/profile/profile-details.vue"),
        ProfileDelete: () => import(/* webpackChunkName: "chunk-profile-delete" */"./components/account/profile/profile-delete.vue"),
        ProfileSummary: () => import(/* webpackChunkName: "chunk-profile-summary" */"./components/account/profile/profile-summary.vue"),
        ProfileAlbumListing: () => import(/* webpackChunkName: "chunk-profile-album-listing" */ './components/account/profile/album/profile-album-listing.vue'),
        ProfileAlbumUpsert: () => import(/* webpackChunkName: "chunk-profile-album-upsert" */ './components/account/profile/album/profile-album-upsert.vue'),
        ProfileAlbumDetail: () => import(/* webpackChunkName: "chunk-profile-album-detail" */ './components/account/profile/album/profile-album-detail.vue'),
        ManageCompletedProfiles: () => import(/* webpackChunkName: "chunk-manage-completed-profiles" */ './components/account/profile/manage/manage-completed-profiles.vue'),
        ManageIncompleteProfiles: () => import(/* webpackChunkName: "chunk-manage-incomplete-profiles" */ './components/account/profile/manage/manage-incomplete-profiles.vue'),
        LightboxProfileCompleted: () => import(/* webpackChunkName: "chunk-lightbox-profile-completed" */ './components/account/profile/lightbox-profile-completed.vue'),
        LightboxShareVerification: () => import(/* webpackChunkName: "chunk-lightbox-share-verification" */ './components/lightbox-share-verification.vue'),
        CountrySelect: () => import(/* webpackChunkName: "chunk-country-select" */ './components/country-select.vue'),
        InputDigits: () => import(/* webpackChunkName: "chunk-input-digits" */ './components/input/input-digits.vue'),
        ProfileSelect: () => import(/* webpackChunkName: "chunk-profile-select" */ './components/profile-select.vue'),
        MultiProfileInitVerification: () => import(/* webpackChunkName: "chunk-multi-profile-init-verification" */ './components/multi-profile-init-verification.vue'),
        MultiProfileVerificationCompleted: () => import(/* webpackChunkName: "chunk-multi-profile-verification-completed" */ './components/account/profile/multi-profile-verification-completed.vue'),

        PromotionTopBanner: () => import(/* webpackChunkName: "chunk-promotion-top-banner" */ './components/promotion-top-banner.vue'),
    }
});