<template>
    <button :class="classes" :href="href" @click.stop="onClick"
    id="phone-button"
    >
        <i v-if="hideText" class="icon-phone align-middle"></i>
        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="mr-1 mr-sm-2" width="18" height="18">
            <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clip-rule="evenodd"></path>
        </svg>
        <span v-html="buttonText"></span>
    </button>
</template>

<script>
// import Translations from "./insert-me-to-translate.vue";

import Url from "domurl";
import analyticsMixin from "./mixins/analytics-mixin";

export default {
    props: {
        number: {
            type: String,
            required: true
        },
        telephoneId: {
            type: [String, Number],
            required: true
        },
        postId: {
            type: [String, Number],
            required: true
        },
        postPublicId: {
            type: [String, Number],
            required: true
        },
        callsCounterUrl: {
            type: String,
            required: true
        },
        pageType: {
            type: String,
            required: false,
            default: null,
        },
        pageNumber: {
            type: [String, Number],
            required: false,
            default: null
        },
        enableShowNumberListener: {
            type: Boolean,
            default: false
        },
        isDetailView: {
            type: Boolean,
            default: false
        },
        hideText: {
            type: Boolean,
            default: false
        },
        showPhoneText: {
            type: Boolean,
            default: false
        }
    },
    mixins: [analyticsMixin],
    data() {
        return  {
            ignoreClick: false,
            eventName: 'phone-button:show-telephone-number',
            text: ''
        };
    },
    computed: {
        classes() {
            return {
                "btn-primary-phonedisabled w-auto px-3": this.ignoreClick && this.pageType === 'listing',
            };
        },
        buttonText() {
            if (this.text === this.number) {
                if (this.number.length >= 3) {
                    return `${this.number.slice(0, 3)}&#8203;${this.number.slice(3)}`;
                }
                return this.number;
            }

            return this.text;
        },
        href() {
            return  `tel:${this.number}`
        },
    },
    created: function() {
        if (this.pageType !== 'detail') {
            this.text = "";
            return;
        }

        if (this.showPhoneText){
           return this.text = this.number;
        }

        this.$root.isMobile ? this.text = gettext("call me") : this.text = gettext("telephone");
    },
    mounted: function() {
        if(this.enableShowNumberListener) {
            window.addEventListener(this.eventName, this.showTelephoneNumber);
        }
    },
    beforeDestroy() {
        if(this.enableShowNumberListener) {
            window.removeEventListener(this.eventName, this.showTelephoneNumber);
        }
    },
    methods: {
        showTelephoneNumber(event) {
          if(!event.detail.mobile && event.detail.number === this.number){
              this.text = this.number;
              this.ignoreClick = true;
          }
        },
        onClick() {
            if(this.ignoreClick) {
                // ignore multiple clicks in non-mobile browsers
                return;
            }

            // analytics
            this.contactButtonAnalytics(this.isDetailView, this.postPublicId, 'tel');

            // send event to other buttons
            const isMobile = window.app.isMobile;

            if(!isMobile || this.text.indexOf('...') > 0){
                this.ignoreClick = true;
                this.text = this.number;
                let event = new CustomEvent(this.eventName, {detail: {number: this.number, mobile: isMobile}});
                window.dispatchEvent(event);
                if (!this.showTelephoneNumber) return this.showTelephoneNumber(event);
            }

            // save current url
            const url = new Url(window.location.href);

            // open link on mobile devices only
            if(isMobile) {
                window.location.href = this.href;
            }

            // increase calls click counter
            this.$http.post(
                this.callsCounterUrl,
                {
                    id: this.telephoneId,
                    post: this.postId,
                    ct: 'telephone',
                    pt: this.pageType,
                    rs: url.query.source || null, // referer source
                    rp: url.query.page || this.pageNumber || null, // page (referer or listing)
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            ).catch(console.error);
        }

    }
};
</script>
<style>
@media (max-width: 768px) {
  #phone-button {
    white-space: nowrap;
  }
}
</style>
