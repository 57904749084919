// ui-button
export function buttonAppearanceValidator(value) {
    return ['primary', 'secondary', 'tertiary', 'danger', 'delete'].includes(value);
}

export function buttonTypeValidator(value) {
    return ['fill', 'outline', 'blank'].includes(value);
}

export function buttonSizeValidator(value) {
    return ['normal', 'small'].includes(value);
}

// ui-modal
export function modalSizeValidator(value) {
    return ['md', 'lg'].includes(value);
}

